import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef, GridToolbar, GridRowSelectionModel, GridRowId } from "@mui/x-data-grid-pro";
import { useCallback, useMemo, useState } from "react";
import AssysUser from "../../models/AssysUser";
import SystemRole from "../../models/SystemRole";
import { SystemReviewResult } from "../../models/enums/SystemReviewResult";
import UserAccountReviewActionResult from "../../models/UserAccountReviewActionResult";
import { useCreateUserAccountReview } from "../../queries/UserAccountReviewQueries";

interface ReviewUserAccountRolesDialogProps {
    accountName: string,
    setAccountName: (id: string) => void,
    assysUser: AssysUser,
    refetchAssysUser: () => void
}

export default function ReviewUserAccountRolesDialog(props: ReviewUserAccountRolesDialogProps) {
    const roleList: SystemRole[] = props.assysUser.systems?.filter(system => system.name === props.accountName)[0]?.roleList ?? [];
    const systemId: string = props.assysUser.systems?.filter(system => system.name === props.accountName)[0]?.systemId ?? "unknown";
    const [reviewAction, setReviewAction] = useState("");
    const [rowSelectionModel, SetRowSelectionModel] = useState<GridRowSelectionModel>([]);
    
    const columns: GridColDef[] = useMemo(() => [
        { field: "applicationName", headerName: "Application", width: 150 },
        { field: "name", headerName: "Role", width: 170 },
        { field: "accessLevel", headerName: "Access Level", width: 130 },
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF
        }
    ] as GridColDef[], []);

    const handleClickCancelDialog = () => {
        props.setAccountName("");
        setReviewAction("");
        SetRowSelectionModel([]);
    };

    const { mutate: createUserAccountReview } = useCreateUserAccountReview(props.assysUser.employeeId, systemId, {
        onSuccess: (data) => {
            props.refetchAssysUser();
            props.setAccountName("");
            setReviewAction("");
            SetRowSelectionModel([]);
        }
    });

    function handleClickSubmit() {
        let reviewResult: UserAccountReviewActionResult = {
            result: reviewAction,
            roles: rowSelectionModel.map((roleId) => ({ id: roleId.toString() }))
        };

        createUserAccountReview(reviewResult);        
    }

    return (
        <Dialog open={!!props.accountName} maxWidth="lg">
            <DialogTitle>Review Account Roles for {props.accountName}:</DialogTitle>
            <DialogContent sx={{ minWidth: "500px" }}>
                <Grid2 container spacing={1}>
                    <Grid2 xs={4}>
                        <Typography fontWeight="600">Review action:</Typography>
                    </Grid2>
                    <Grid2>
                        <Select
                            value={reviewAction}
                            onChange={(e) => setReviewAction(e.target.value)}
                            variant="standard"
                            margin="dense"
                            type="text"
                            sx={{ width: "150px", fontSize: "0.875rem" }}
                        >
                            {Object.values(SystemReviewResult).map((ra) => <MenuItem key={ra} value={ra}>{ra}</MenuItem>)}
                        </Select>
                    </Grid2>
                </Grid2>
                <br />
                <Grid2 container>
                    <Grid2>
                        {
                            reviewAction === SystemReviewResult.RemoveRoles &&
                            <Box
                                sx={{
                                    display: 'flex', height: '40vh',
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: '600',
                                    },
                                }}
                            >
                                <DataGridPro columns={columns} rows={roleList} density="compact" sx={{ padding: "10px 20px", width: "560px" }}
                                    slots={{
                                        toolbar: GridToolbar,
                                    }}
                                    slotProps={{
                                        toolbar: { showQuickFilter: true }
                                    }}
                                    checkboxSelection
                                    onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
                                        SetRowSelectionModel(newRowSelectionModel);
                                    }}
                                    rowSelectionModel={rowSelectionModel}
                                />
                            </Box>
                        }
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Close dialog</Button>
                <Button variant="contained" onClick={handleClickSubmit}>Submit review</Button>
            </DialogActions>
        </Dialog>
    );
}