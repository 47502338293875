import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DataGridPro, GridColDef, GridToolbar } from "@mui/x-data-grid-pro";
import moment from "moment";
import { useMemo } from "react";

interface UserAccountHistoryDialogProps {
    accountName: string,
    setAccountName: (id: string) => void,
    accountHistoryList: any,
    setAccountHistoryList: (historyList: any) => void,
}

export default function UserAccountHistoryDialog(props: UserAccountHistoryDialogProps) {

    const columns: GridColDef[] = useMemo(() => [
        { field: 'action', headerName: "Action", width: 150 },
        { field: 'timestamp', headerName: "Action Date", valueGetter: ({ value }) => value ? moment(value).format("yyyy-MM-DD HH:mm:ss.SSS") : '', width: 250 },               
    ] as GridColDef[], []);

    const handleClickCancelDialog = () => {
        props.setAccountName("");
        props.setAccountHistoryList([]);
    };

    return (
        <Dialog open={!!props.accountName}>
            <DialogTitle>Account History: {props.accountName}</DialogTitle>

            <DialogContent>
                <Box
                    sx={{
                        display: 'flex', width: '100%', height: '40vh',
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: '600',
                        },
                    }}
                >
                    <DataGridPro columns={columns} rows={props.accountHistoryList ?? []} density="compact" sx={{ padding: '10px 20px' }}                       
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        slotProps={{
                            toolbar: { showQuickFilter: true }
                        }}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Close dialog</Button>
            </DialogActions>
        </Dialog>
    );
}