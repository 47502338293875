import { Apps, PeopleAlt, Report } from '@mui/icons-material';
import { useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LayoutProvider, LeftDrawerItem, User, useUser } from 'wcz-layout';
import packageJson from '../package.json';
import SystemDetail from './pages/SystemDetail';
import Systems from './pages/Systems';
import EmployeeDetail from './pages/EmployeeDetail';
import HomePage from './pages/HomePage';
import InactiveEmployees from './pages/InactiveEmployees';

export default function App() {
    const user: User = useUser();

    const leftDrawerItems: LeftDrawerItem[] = useMemo(() => [
        { title: "Employees", path: "/", icon: <PeopleAlt fontSize="large" /> },
        { title: "Systems", path: "/systems", icon: <Apps fontSize="large" /> },
        { title: "Inactive Employees", path: "/report/inactive-employees", icon: <Report fontSize="large" /> },
    ], [user]);

    return (
        <BrowserRouter>
            <LayoutProvider title={"Assys - IT Asset Overview System"} appVersion={packageJson.version} primaryColor={"#f44336"} secondaryColor={"#f4a236"} leftDrawerItems={leftDrawerItems}>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/systems' element={<Systems />} />
                    <Route path='/systems/:systemId' element={<SystemDetail />} />
                    <Route path='/report/inactive-employees' element={<InactiveEmployees />} />
                    <Route path='/employee/:employeeId' element={<EmployeeDetail />} />
                </Routes>
            </LayoutProvider>
        </BrowserRouter>
    );
}
