import { Box, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LayoutContext, fetchGet, newGuid, persistStorage } from 'wcz-layout';
import Employee from '../models/Employee';
import { apiUrl } from '../utils/BaseUrl';

interface CustomToolbarProps {
    handleSwitchShowTerminatedChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleSwitchFilterInvalid: (event: React.ChangeEvent<HTMLInputElement>) => void,
    filterInvalid: boolean,
}

function CustomToolbar(props: CustomToolbarProps) {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />            
            <FormGroup>
                <FormControlLabel control={<Switch onChange={props.handleSwitchShowTerminatedChange} />} label="Show terminated" />
            </FormGroup>
            <FormGroup>
                <FormControlLabel control={<Switch onChange={props.handleSwitchFilterInvalid} checked={props.filterInvalid} />} label="Need review?" />
            </FormGroup>
            <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
        </GridToolbarContainer>
    );
}

export default function HomePage() {
    const { user } = useContext(LayoutContext);
    const navigate = useNavigate();
    const employeesTableApiRef = useGridApiRef();
    const [showTerminated, setShowTerminated] = useState(false);
    const [filterInvalid, setFilterInvalid] = useState(persistStorage.getObject("HomePage-EmployeesTable-filterInvalid") ?? false);

    function handleSwitchShowTerminatedChange(event: React.ChangeEvent<HTMLInputElement>) {
        setShowTerminated(event.target.checked);
    }

    function handleSwitchFilterInvalid(event: React.ChangeEvent<HTMLInputElement>) {
        setFilterInvalid(event.target.checked);

        persistStorage.setObject("HomePage-EmployeesTable-filterInvalid", event.target.checked);
    }

    const { data: employees = [] } = useQuery<Employee[]>(["employees", showTerminated], ({ signal }) => fetchGet(`${apiUrl}/assys/v1/user/employees?terminated=${showTerminated}`, signal), {
        onSuccess: (data) => {
            if (data.length === 0) {
                navigate(`/employee/${user.id}`);
            }
        }
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: "companyCode", headerName: "Company", width: 100 },
        { field: "employeeId", headerName: "Employee Id", width: 120 },
        { field: "name", headerName: "Name", width: 230 },
        { field: "department", headerName: "Department", width: 120 },
        { field: "category", headerName: "Category", width: 100 },
        { field: "position", headerName: "Position", width: 220 },
        { field: "status", headerName: "Status", width: 100 },
        { field: "hiredDate", headerName: "Hired Date", valueGetter: ({ value }) => value ? moment(value).format("yyyy-MM-DD") : '', width: 120 },
        { field: "terminationDate", headerName: "Termination Date", valueGetter: ({ value }) => value ? moment(value).format("yyyy-MM-DD") : '', width: 140 },
        { field: "reviewed", headerName: "Valid Review", type: "boolean", width: 120 }
    ] as GridColDef[], []);

    function handleEmployeesTableStateChanged() {
        let state: GridInitialStatePro = employeesTableApiRef.current.exportState();
        state = { ...state, preferencePanel: undefined };

        persistStorage.setObject(`HomePage-EmployeesTable-state`, state);
    }

    return (
        <Box
            sx={{
                display: 'flex', width: '100%', height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }, p: '10px 20px 5px',
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: '600',
                },
                '& .MuiDataGrid-booleanCell[data-value="true"]': {
                    color: 'green!important'
                },
                '& .MuiDataGrid-booleanCell[data-value="false"]': {
                    color: 'red!important'
                }
            }}
        >
            <DataGridPro columns={columns} rows={filterInvalid ? employees.filter(emp => emp.reviewed === false) : employees} density="compact" sx={{ border: "0px", maxWidth: "1400px" }}
                apiRef={employeesTableApiRef}
                getRowId={() => newGuid()}
                initialState={persistStorage.getObject(`HomePage-EmployeesTable-state`) ?? undefined}
                onSortModelChange={handleEmployeesTableStateChanged}
                onFilterModelChange={handleEmployeesTableStateChanged}
                onPinnedColumnsChange={handleEmployeesTableStateChanged}
                onColumnOrderChange={handleEmployeesTableStateChanged}
                onColumnVisibilityModelChange={handleEmployeesTableStateChanged}
                onColumnWidthChange={handleEmployeesTableStateChanged}
                onCellDoubleClick={params => navigate("/employee/" + params.row.employeeId)}
                slots={{
                    toolbar: CustomToolbar,
                }}
                slotProps={{
                    toolbar: { handleSwitchShowTerminatedChange, handleSwitchFilterInvalid, filterInvalid }
                }}
            />
        </Box>
    );
}
