import { Box } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbar, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchGet, newGuid, persistStorage } from 'wcz-layout';
import { apiUrl } from '../utils/BaseUrl';

export default function Systems() {
    const navigate = useNavigate();
    const systemsTableApiRef = useGridApiRef();

    const { data: systems = [] } = useQuery(["systems"], ({ signal }) => fetchGet(`${apiUrl}/assys/v1/system`, signal));

    const columns: GridColDef[] = useMemo(() => [
        { field: 'name', headerName: "Name", width: 500 },        
        { field: 'activeUsers', headerName: "Active Users", width: 200 },        
    ] as GridColDef[], []);

    function handleSystemsTableStateChanged() {
        let state: GridInitialStatePro = systemsTableApiRef.current.exportState();
        state = { ...state, preferencePanel: undefined };

        persistStorage.setObject(`SystemsPage-Table-state`, state);
    }

    return (
        <Box
            sx={{
                display: 'flex', width: '100%', maxWidth: '910px', height: '86vh', p: '10px',
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: '600',
                },
            }}
        >
            <DataGridPro columns={columns} rows={systems} density="compact" sx={{ padding: '10px 20px' }}
                apiRef={systemsTableApiRef}
                getRowId={() => newGuid()}
                initialState={persistStorage.getObject(`SystemsPage-Table-state`) ?? undefined}
                onSortModelChange={handleSystemsTableStateChanged}
                onFilterModelChange={handleSystemsTableStateChanged}
                onPinnedColumnsChange={handleSystemsTableStateChanged}
                onColumnOrderChange={handleSystemsTableStateChanged}
                onColumnVisibilityModelChange={handleSystemsTableStateChanged}
                onColumnWidthChange={handleSystemsTableStateChanged}
                onCellDoubleClick={params => navigate("/systems/" + params.row.id)}
                slots={{
                    toolbar: GridToolbar,
                }}
                slotProps={{
                    toolbar: { showQuickFilter: true }
                }}
            />
        </Box>
    );
}
