import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchGet, newGuid, persistStorage } from "wcz-layout";
import System from "../models/System";
import { apiUrl } from "../utils/BaseUrl";

export default function SystemDetail() {
    const { systemId } = useParams();
    const navigate = useNavigate();
    const { data: system = {} as System } = useQuery<System>(["system", systemId], ({ signal }) => fetchGet(`${apiUrl}/assys/v1/system/${systemId}`, signal));
    const usersTableApiRef = useGridApiRef();

    useEffect(() => {
        const state = persistStorage.getObject(`${system.name}-UsersTable-state`) ?? undefined;
        if (state && system.name)
            usersTableApiRef.current.restoreState(state);
    }, [system]);

    const columns: GridColDef[] = useMemo(() => [
        { field: 'userType', headerName: "User Type", width: 150 },
        { field: 'companyCode', headerName: "Company", width: 100 },
        { field: 'employeeId', headerName: "Employee Id", width: 150 },
        { field: 'name', headerName: "Name", width: 220 },
        { field: 'department', headerName: "Department", width: 130 },
        { field: 'category', headerName: "Category", width: 100 },
        { field: 'position', headerName: "Position", width: 250 },
        { field: 'status', headerName: "Status", width: 120 },
        { field: 'hiredDate', headerName: "Hired Date", valueGetter: ({ value }) => value ? moment(value).format("yyyy-MM-DD") : '', width: 150 },
        { field: 'terminationDate', headerName: "Termination Date", valueGetter: ({ value }) => value ? moment(value).format("yyyy-MM-DD") : '', width: 150 },
        { field: 'systemActive', type: "boolean", headerName: "Active", width: 80 },
        { field: 'systemLastAction', headerName: "Last Action", width: 120 },
        { field: 'systemLastChange', headerName: "Last Change", valueGetter: ({ value }) => value ? moment(value).format("yyyy-MM-DD HH:mm:ss.SSS") : '', width: 220 },
    ] as GridColDef[], []);

    function handleUsersTableStateChanged() {
        let state: GridInitialStatePro = usersTableApiRef.current.exportState();
        state = { ...state, preferencePanel: undefined };
        
        persistStorage.setObject(`${system.name}-UsersTable-state`, state);
    }

    return (
        <Grid container spacing={1} sx={{ p: "8px", }} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Card variant="outlined">
                    <CardHeader title={system.name ?? "Application Title"} />   
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={4} md={1} sx={{ fontWeight: 600 }}>
                                Active users:
                            </Grid>
                            <Grid item xs={8} md={11}>
                                {system.activeUsers}
                            </Grid>                            
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={4} md={1} sx={{ fontWeight: 600 }}>
                                Disabled users:
                            </Grid>
                            <Grid item xs={8} md={11}>
                                {system.disabledUsers}
                            </Grid>                            
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={4} md={1} sx={{ fontWeight: 600 }}>
                                Deleted users:
                            </Grid>
                            <Grid item xs={8} md={11}>
                                {system.deletedUsers}
                            </Grid>                            
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ pt: "15px", pl: "10px" }} gutterBottom component="div">System users</Typography>
                <Box
                    sx={{
                        display: 'flex', width: '100%', height: '70vh',
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: '600',
                        },
                        '& .MuiDataGrid-booleanCell[data-value="true"]': {
                            color: 'green!important'
                        },
                        '& .MuiDataGrid-booleanCell[data-value="false"]': {
                            color: 'red!important'
                        }
                    }}
                >
                    <DataGridPro columns={columns} rows={system.users ?? []} density="compact" sx={{ width: '100%', padding: '10px 20px' }}
                        apiRef={usersTableApiRef}
                        getRowId={() => newGuid()}                           
                        onSortModelChange={handleUsersTableStateChanged}
                        onFilterModelChange={handleUsersTableStateChanged}
                        onPinnedColumnsChange={handleUsersTableStateChanged}
                        onColumnOrderChange={handleUsersTableStateChanged}
                        onColumnVisibilityModelChange={handleUsersTableStateChanged}
                        onColumnWidthChange={handleUsersTableStateChanged}
                        onCellDoubleClick={params => navigate("/employee/" + params.row.employeeId)}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        slotProps={{
                            toolbar: { showQuickFilter: true }
                        }}
                    />
                </Box>
            </Grid>            
        </Grid>
    );
}