import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DataGridPro, GridColDef, GridToolbar } from "@mui/x-data-grid-pro";
import moment from "moment";
import { useMemo } from "react";
import AssysUser from "../../models/AssysUser";
import SystemRole from "../../models/SystemRole";

interface UserAccountRolesDialogProps {
    accountName: string,
    setAccountName: (id: string) => void,
    assysUser: AssysUser
}

export default function UserAccountRolesDialog(props: UserAccountRolesDialogProps) {
    const roleList: SystemRole[] = props.assysUser.systems?.filter(system => system.name === props.accountName)[0]?.roleList ?? [];

    const columns: GridColDef[] = useMemo(() => [
        { field: 'applicationName', headerName: "Application", width: 150 },
        { field: 'name', headerName: "Role", width: 170 },
        { field: 'accessLevel', headerName: "Access Level", width: 130 },
        { field: 'action', headerName: "Action", width: 100 },
        { field: 'timestamp', headerName: "Timestamp", valueGetter: ({ value }) => value ? moment(value).format("yyyy-MM-DD HH:mm:ss.SSS") : '', width: 210 },
    ] as GridColDef[], []);

    const handleClickCancelDialog = () => {
        props.setAccountName("");
    };

    return (
        <Dialog open={!!props.accountName} maxWidth="lg">
            <DialogTitle>Account Roles: {props.accountName}</DialogTitle>

            <DialogContent>
                <Box
                    sx={{
                        display: 'flex', height: '40vh',
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: '600',
                        },
                    }}
                >
                    <DataGridPro columns={columns} rows={roleList} density="compact" sx={{ padding: "10px 20px", width: "810px" }}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        slotProps={{
                            toolbar: { showQuickFilter: true }
                        }}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Close dialog</Button>
            </DialogActions>
        </Dialog>
    );
}