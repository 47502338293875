import { useContext } from "react";
import UserAccountReviewActionResult from "../models/UserAccountReviewActionResult";
import { LayoutContext, fetchPost } from "wcz-layout";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUrl } from "../utils/BaseUrl";
import AssysUser from "../models/AssysUser";

interface UseCreateOptions {
    onSuccess?: (data: AssysUser) => void,
    onError?: (message: string) => void,
}

export function useCreateUserAccountReview(userId: string, systemId: string, options?: UseCreateOptions) {
    const queryClient = useQueryClient();

    return useMutation((model: UserAccountReviewActionResult) => fetchPost(`${apiUrl}/assys/v1/user/${userId}/${systemId}/review`, model), {
        onSuccess: (model, variables, context) => {
            if (context) {
                queryClient.setQueryData(["assysuser", userId], model);
            }
            if (options?.onSuccess)
                options.onSuccess(model);
        },
    });
}