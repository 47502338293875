import { History, HowToReg, PermDataSetting, Reviews, TransferWithinAStation } from "@mui/icons-material";
import { Badge, Box, Card, CardContent, CardHeader, Grid, Tooltip, Typography } from "@mui/material";
import { DataGridPro, GridActionsCellItem, GridColDef, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchGet, newGuid, persistStorage } from "wcz-layout";
import ReviewUserAccountRolesDialog from "../components/employeeDetail/ReviewUserAccountRolesDialog";
import UserAccountHistoryDialog from "../components/employeeDetail/UserAccountHistoryDialog";
import UserAccountRolesDialog from "../components/employeeDetail/UserAccountRolesDialog";
import AssysUser from "../models/AssysUser";
import Employee from "../models/Employee";
import FixedAsset from "../models/FixedAsset";
import ItEqEmployee from "../models/ItEqEmployee";
import { apiUrl } from "../utils/BaseUrl";
import UserAccountReviewListDialog from "../components/employeeDetail/UserAccountReviewListDialog";


export default function EmployeeDetail() {
    const { employeeId } = useParams();

    const { data: assysUser = {} as AssysUser, refetch: refetchAssysUser, isError, isFetching } = useQuery<AssysUser>(["assysuser", employeeId], ({ signal }) => fetchGet(`${apiUrl}/assys/v1/user/${employeeId}`, signal));    

    const { data: manager = {} as Employee } = useQuery<Employee>(["manager", employeeId], ({ signal }) => fetchGet(`${apiUrl}/ps/v1/employee/${employeeId}/manager`, signal), {
        enabled: assysUser.userType === "Employee",
    });
    
    const { data: fixedAssets = [] } = useQuery<FixedAsset[]>(["employeefas", employeeId], ({ signal }) => fetchGet(`https://api.dev.wistron.eu/myfa/v1/fixedasset?search=keeperId==${employeeId}`, signal), {
        enabled: assysUser.userType === "Employee",
    });

    const { data: itEqEmployee = {} as ItEqEmployee } = useQuery<ItEqEmployee>(["employeeiteqmats", employeeId], ({ signal }) => fetchGet(`${apiUrl}/it-eqroom/v1/employee/${employeeId}`, signal), {
        enabled: assysUser.userType === "Employee",
        onError: (err: any) => { if (err.status === 404) return; }
    });

    const [accountToOpen, setAccountToOpen] = useState("");
    const [accountHistoryList, setAccountHistoryList] = useState([]);
    function handleClickUserAccountHistoryList(accountName: string, historyList: any) {
        setAccountToOpen(accountName);
        setAccountHistoryList(historyList);
    }

    const [accountToOpenRoles, setAccountToOpenRoles] = useState("");
    function handleClickUserAccountRoles(systemName: string) {
        setAccountToOpenRoles(systemName);
    }

    const [accountToOpenReviewList, setAccountToOpenReviewList] = useState("");
    function handleClickReviewList(systemName: string) {
        setAccountToOpenReviewList(systemName);
    }

    const [accountToOpenReviewRoles, setAccountToOpenReviewRoles] = useState("");
    function handleClickReviewUserAccountRoles(systemName: string) {
        setAccountToOpenReviewRoles(systemName);
    }

    const userAccountsTableApiRef = useGridApiRef();
    const userAccountsColumns: GridColDef[] = useMemo(() => [
        { field: 'name', headerName: "Name", width: 350 },
        { field: 'active', headerName: "Active", type: "boolean", width: 80 },
        { field: 'lastChange', headerName: "Last Change", valueGetter: ({ value }) => value ? moment(value).format("yyyy-MM-DD HH:mm:ss.SSS") : '', width: 250 },
        { field: 'lastAction', headerName: "Last Action", width: 200 },
        { field: 'lastReviewDate', headerName: "Last Review Date", valueGetter: ({ value }) => value ? moment(value).format("yyyy-MM-DD HH:mm:ss.SSS") : '', width: 250 },
        { field: 'lastReviewResult', headerName: "Last Review Result", width: 200 },
        { field: "reviewed", headerName: "Valid Review", type: "boolean", width: 120 },
        {
            field: 'actions', type: 'actions', width: 150, align: 'center',
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon={
                        <Tooltip title="Account history">
                            <Badge
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={<span style={{ color: "#f4a236" }}>{params.row.historyList.length ?? 0}</span>}
                            >
                                <History />
                            </Badge>                        
                        </Tooltip>
                    }
                    onClick={() => handleClickUserAccountHistoryList(params.row.name, params.row.historyList)}
                    label="Action History"
                />,
                <GridActionsCellItem
                    icon={<Tooltip title="View Permissions"><PermDataSetting /></Tooltip>}
                    onClick={() => handleClickUserAccountRoles(params.row.name)}
                    label="View Permissions"
                />,
                <GridActionsCellItem
                    icon={<Tooltip title="View Revisions"><Reviews /></Tooltip>}
                    onClick={() => handleClickReviewList(params.row.name)}
                    label="View Revisions" />,
                <GridActionsCellItem
                    icon={<Tooltip title="Review Permissions"><HowToReg /></Tooltip>}
                    onClick={() => handleClickReviewUserAccountRoles(params.row.name)}
                    label="Review Permissions"
                />,
            ]
        },
    ] as GridColDef[], []);

    function handleUserAccountsTableStateChanged() {
        let state: GridInitialStatePro = userAccountsTableApiRef.current.exportState();
        state = { ...state, preferencePanel: undefined };

        persistStorage.setObject(`EmployeeDetail-UserAccountsTable-state`, state);
    }

    const fixedAssetsTableApiRef = useGridApiRef();
    const fixedAssetsColumns: GridColDef[] = useMemo(() => [
        {
            field: 'hasTransfer', headerName: '', width: 50,
            renderCell: ({ value }) => value ? <Tooltip title="Transfer in progress"><TransferWithinAStation /></Tooltip> : ''
        },
        { field: 'assetNumber', headerName: "Asset Number", width: 150 },
        { field: 'englishName', headerName: "English Name", width: 350 },
        { field: 'serialNumber', headerName: "Serial Number", width: 200 },
        { field: 'quantity', headerName: "Quantity", width: 100, type: "number" },
        { field: 'location', headerName: "Location", width: 150 },
        { field: 'locationName', headerName: "Location Name", width: 250 },
        { field: 'price', headerName: "Price", width: 150, type: "number" },
        { field: 'netPrice', headerName: "Net Price", width: 150, type: "number" },
        { field: 'currency', headerName: "Currency", width: 100 },
        { field: 'status', headerName: "Status", width: 135 },
    ] as GridColDef[], []);

    function handleFixedAssetsTableStateChanged() {
        let state: GridInitialStatePro = fixedAssetsTableApiRef.current.exportState();
        state = { ...state, preferencePanel: undefined };

        persistStorage.setObject(`EmployeeDetail-FixedAssetsTable-state`, state);
    }

    const itEqMaterialsTableApiRef = useGridApiRef();
    const itEqMaterialsColumns: GridColDef[] = useMemo(() => [
        { field: 'fixAsset', headerName: "Asset Number", width: 150 },
        { field: 'name', headerName: "Name", width: 350 },
        { field: 'serialNumber', headerName: "Serial Number", width: 200 },
        { field: 'location', headerName: "Location", valueGetter: ({ value }) => value?.name, width: 330 },
        { field: 'orderDate', headerName: "Ordered", valueGetter: ({ value }) => value ? moment(value).format("yyyy-MM-DD") : '', width: 250 },
    ] as GridColDef[], []);

    function handleItEqMaterialsTableStateChanged() {
        let state: GridInitialStatePro = itEqMaterialsTableApiRef.current.exportState();
        state = { ...state, preferencePanel: undefined };

        persistStorage.setObject(`EmployeeDetail-ItEqMaterialsTable-state`, state);
    }

    if (isFetching) return <></>;
    if (isError) { return <Box sx={{ margin: "40vh 40vw" }}>Not authorized!</Box>; }
    else {
        return (
            <Grid container spacing={1} sx={{ p: "8px", }} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardHeader title={assysUser.name + " (" + employeeId + ")"} subheader={assysUser.userType === "Employee" ? assysUser.position + " at " + assysUser.department : ""} />
                        <CardContent>
                            {
                                assysUser.userType === "Employee" &&
                                <Grid container>
                                    <Grid item xs={4} md={2} sx={{ fontWeight: 600 }}>
                                        User Type:
                                    </Grid>
                                    <Grid item xs={8} md={10}>
                                        {assysUser.userType}
                                    </Grid>
                                    <Grid item xs={4} md={2} sx={{ fontWeight: 600 }}>
                                        Hire Date:
                                    </Grid>
                                    <Grid item xs={8} md={10}>
                                        {assysUser.hiredDate}
                                    </Grid>
                                    {
                                        assysUser.terminationDate &&
                                        <React.Fragment>
                                            <Grid item xs={4} md={2} sx={{ fontWeight: 600 }}>
                                                Termination Date:
                                            </Grid>
                                            <Grid item xs={8} md={10}>
                                                {assysUser.terminationDate}
                                            </Grid>
                                        </React.Fragment>
                                    }
                                    <Grid item xs={4} md={2} sx={{ fontWeight: 600 }}>
                                        Status:
                                    </Grid>
                                    <Grid item xs={8} md={10}>
                                        {assysUser.status}
                                    </Grid>
                                    <Grid item xs={4} md={2} sx={{ fontWeight: 600 }}>
                                        Category:
                                    </Grid>
                                    <Grid item xs={8} md={10}>
                                        {assysUser.category}
                                    </Grid>
                                    {
                                        assysUser.mail?.trim() &&
                                        <React.Fragment>
                                            <Grid item xs={4} md={2} sx={{ fontWeight: 600 }}>
                                                Email:
                                            </Grid>
                                            <Grid item xs={8} md={10}>
                                                {assysUser.mail}
                                            </Grid>
                                        </React.Fragment>
                                    }
                                    <Grid item xs={4} md={2} sx={{ fontWeight: 600 }}>
                                        Manager:
                                    </Grid>
                                    <Grid item xs={8} md={10}>
                                        {`${manager.nameTitleCase} (${manager.employeeId}) at ${manager.departmentId}`}
                                    </Grid>
                                </Grid>
                            }
                            {
                                assysUser.userType !== "Employee" &&
                                <Grid container>
                                    <Grid item xs={4} md={2} sx={{ fontWeight: 600 }}>
                                        User Type:
                                    </Grid>
                                    <Grid item xs={8} md={10}>
                                        {assysUser.userType}
                                    </Grid>
                                    <Grid item xs={4} md={2} sx={{ fontWeight: 600 }}>
                                        Status:
                                    </Grid>
                                    <Grid item xs={8} md={10}>
                                        {assysUser.status}
                                    </Grid>
                                </Grid>
                            }

                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" sx={{ pt: "15px", pl: "10px" }} gutterBottom component="div">User accounts</Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ pl: "10px" }} gutterBottom component="div">List of user's accounts.</Typography>
                    <Box
                        sx={{
                            display: 'flex', width: '100%',
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: '600',
                            },
                            '& .MuiDataGrid-booleanCell[data-value="true"]': {
                                color: 'green!important'
                            },
                            '& .MuiDataGrid-booleanCell[data-value="false"]': {
                                color: 'red!important'
                            }
                        }}
                    >
                        <DataGridPro columns={userAccountsColumns} rows={assysUser.systems ?? []} density="compact" sx={{ padding: "10px 20px", width: "1500px", minHeight: "300px", maxHeight: "40vh" }}
                            apiRef={userAccountsTableApiRef}
                            getRowId={() => newGuid()}
                            initialState={persistStorage.getObject(`EmployeeDetail-UserAccountsTable-state`) ?? undefined}
                            onSortModelChange={handleUserAccountsTableStateChanged}
                            onFilterModelChange={handleUserAccountsTableStateChanged}
                            onPinnedColumnsChange={handleUserAccountsTableStateChanged}
                            onColumnOrderChange={handleUserAccountsTableStateChanged}
                            onColumnVisibilityModelChange={handleUserAccountsTableStateChanged}
                            onColumnWidthChange={handleUserAccountsTableStateChanged}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            slotProps={{
                                toolbar: { showQuickFilter: true }
                            }}
                            disableDensitySelector
                        />
                    </Box>
                    <UserAccountHistoryDialog accountName={accountToOpen} setAccountName={setAccountToOpen} accountHistoryList={accountHistoryList} setAccountHistoryList={setAccountHistoryList} />
                    <UserAccountRolesDialog accountName={accountToOpenRoles} setAccountName={setAccountToOpenRoles} assysUser={assysUser} />
                    <UserAccountReviewListDialog accountName={accountToOpenReviewList} setAccountName={setAccountToOpenReviewList} assysUser={assysUser} />
                    <ReviewUserAccountRolesDialog accountName={accountToOpenReviewRoles} setAccountName={setAccountToOpenReviewRoles} assysUser={assysUser} refetchAssysUser={refetchAssysUser} />
                </Grid>
                {
                    assysUser.userType === "Employee" &&
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ pt: "15px", pl: "10px" }} gutterBottom component="div">MyFA Fixed assets</Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ pl: "10px" }} gutterBottom component="div">List of fixed assets from MyFA system.</Typography>
                        <Box
                            sx={{
                                display: 'flex', width: '100%',
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: '600',
                                },
                            }}
                        >
                            <DataGridPro columns={fixedAssetsColumns} rows={fixedAssets ?? []} density="compact" sx={{ padding: "10px 20px", width: "1700px", minHeight: "300px", maxHeight: "40vh" }}
                                apiRef={fixedAssetsTableApiRef}
                                getRowId={() => newGuid()}
                                initialState={persistStorage.getObject(`EmployeeDetail-FixedAssetsTable-state`) ?? undefined}
                                onPaginationModelChange={handleFixedAssetsTableStateChanged}
                                onSortModelChange={handleFixedAssetsTableStateChanged}
                                onFilterModelChange={handleFixedAssetsTableStateChanged}
                                onPinnedColumnsChange={handleFixedAssetsTableStateChanged}
                                onColumnOrderChange={handleFixedAssetsTableStateChanged}
                                onColumnVisibilityModelChange={handleFixedAssetsTableStateChanged}
                                onColumnWidthChange={handleFixedAssetsTableStateChanged}
                                slots={{
                                    toolbar: GridToolbar,
                                }}
                                slotProps={{
                                    toolbar: { showQuickFilter: true }
                                }}
                                disableDensitySelector
                            />
                        </Box>
                    </Grid>
                }
                {
                    assysUser.userType === "Employee" &&
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ pt: "15px", pl: "10px" }} gutterBottom component="div">IT EQ Room assets</Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ pl: "10px" }} gutterBottom component="div">List of assets from IT Equipment room.</Typography>
                        <Box
                            sx={{
                                display: 'flex', width: '100%',
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: '600',
                                },
                            }}
                        >
                            <DataGridPro columns={itEqMaterialsColumns} rows={itEqEmployee.materials ?? []} density="compact" sx={{ padding: "10px 20px", width: "1330px", minHeight: "300px", maxHeight: "40vh" }}
                                apiRef={itEqMaterialsTableApiRef}
                                initialState={persistStorage.getObject(`EmployeeDetail-ItEqMaterialsTable-state`) ?? undefined}
                                onSortModelChange={handleItEqMaterialsTableStateChanged}
                                onFilterModelChange={handleItEqMaterialsTableStateChanged}
                                onPinnedColumnsChange={handleItEqMaterialsTableStateChanged}
                                onColumnOrderChange={handleItEqMaterialsTableStateChanged}
                                onColumnVisibilityModelChange={handleItEqMaterialsTableStateChanged}
                                onColumnWidthChange={handleItEqMaterialsTableStateChanged}
                                slots={{
                                    toolbar: GridToolbar,
                                }}
                                slotProps={{
                                    toolbar: { showQuickFilter: true }
                                }}
                                disableDensitySelector
                            />
                        </Box>
                    </Grid>
                }
            </Grid>
        );
    }
}