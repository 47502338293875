import { Box } from '@mui/material';
import { DataGridPro, GridColDef, GridToolbar, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchGet, newGuid, persistStorage } from 'wcz-layout';
import Employee from '../models/Employee';
import { apiUrl } from '../utils/BaseUrl';

export default function InactiveEmployees() {
    const navigate = useNavigate();
    const employeesTableApiRef = useGridApiRef();

    const { data: employees = [] } = useQuery<Employee[]>(["inactive-employees"], ({ signal }) => fetchGet(`${apiUrl}/assys/v1/user/inactive`, signal));

    const columns: GridColDef[] = useMemo(() => [
        { field: 'companyCode', headerName: "Company", width: 150 },
        { field: 'employeeId', headerName: "Employee Id", width: 150 },
        { field: 'name', headerName: "Name", width: 250 },
        { field: 'department', headerName: "Department", width: 150 },
        { field: 'category', headerName: "Category", width: 100 },
        { field: 'position', headerName: "Position", width: 250 },
        { field: 'status', headerName: "Status", width: 150 },
        { field: 'hiredDate', headerName: "Hired Date", valueGetter: ({ value }) => value ? moment(value).format("yyyy-MM-DD") : '', width: 150 },
        { field: 'terminationDate', headerName: "Termination Date", valueGetter: ({ value }) => value ? moment(value).format("yyyy-MM-DD") : '', width: 150 },
    ] as GridColDef[], []);

    function handleEmployeesTableStateChanged() {
        let state: GridInitialStatePro = employeesTableApiRef.current.exportState();
        state = { ...state, preferencePanel: undefined };

        persistStorage.setObject(`InactiveEmployeesPage-Table-state`, state);
    }

    return (
        <Box
            sx={{
                display: 'flex', width: '100%', maxWidth: '1600px', height: '86vh', p: '10px',
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: '600',
                },
            }}
        >
            <DataGridPro columns={columns} rows={employees} density="compact" sx={{ padding: '10px 20px' }}
                apiRef={employeesTableApiRef}
                getRowId={() => newGuid()}
                initialState={persistStorage.getObject(`InactiveEmployeesPage-Table-state`) ?? undefined}
                onSortModelChange={handleEmployeesTableStateChanged}
                onFilterModelChange={handleEmployeesTableStateChanged}
                onPinnedColumnsChange={handleEmployeesTableStateChanged}
                onColumnOrderChange={handleEmployeesTableStateChanged}
                onColumnVisibilityModelChange={handleEmployeesTableStateChanged}
                onColumnWidthChange={handleEmployeesTableStateChanged}
                onCellDoubleClick={params => navigate("/employee/" + params.row.employeeId)}
                slots={{
                    toolbar: GridToolbar,
                }}
                slotProps={{
                    toolbar: { showQuickFilter: true }
                }}
            />
        </Box>
    );
}
