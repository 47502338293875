import AssysUser from "../../models/AssysUser";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridToolbar } from "@mui/x-data-grid-pro";
import SystemReviewRecord from "../../models/SystemReviewRecord";
import { useMemo } from "react";
import moment from "moment";
import SystemRole from "../../models/SystemRole";

interface UserAccountReviewListDialogProps {
    accountName: string,
    setAccountName: (id: string) => void,
    assysUser: AssysUser
}

export default function UserAccountReviewListDialog(props: UserAccountReviewListDialogProps) {
    const reviewList: SystemReviewRecord[] = props.assysUser.systems?.filter(system => system.name === props.accountName)[0]?.reviewList ?? [];

    const columns: GridColDef[] = useMemo(() => [
        { field: 'reviewDate', headerName: "Review Date", valueGetter: ({ value }) => value ? moment(value).format("yyyy-MM-DD HH:mm:ss.SSS") : '', width: 210 },
        { field: 'reviewer', headerName: "Reviewer", width: 250 },
        { field: 'result', headerName: "Result", width: 170 },
        {
            field: "roles", headerName: "Roles", width: 200,
            renderCell: ({ value }: { value: SystemRole[] }) =>
                <Box>
                    {
                        value.map((role) => <Typography>{role.name}</Typography>)
                    }
                </Box>
        }
    ] as GridColDef[], []);

    const handleClickCancelDialog = () => {
        props.setAccountName("");
    };

    return (
        <Dialog open={!!props.accountName} maxWidth="lg">
            <DialogTitle>Review History for {props.accountName}</DialogTitle>

            <DialogContent>
                <Box
                    sx={{
                        display: 'flex', width: '100%', height: '40vh',
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: '600',
                        },
                    }}
                >
                    <DataGridPro columns={columns} rows={reviewList ?? []} density="compact" sx={{ padding: '10px 20px', width: "900px" }}
                        getRowHeight={() => "auto"}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        slotProps={{
                            toolbar: { showQuickFilter: true }
                        }}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClickCancelDialog}>Close dialog</Button>
            </DialogActions>
        </Dialog>
    );
}